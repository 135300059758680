/* You can add global styles to this file, and also import other style files */
//@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Archivo+Black&family=Fredoka+One&family=Russo+One&display=swap");

// @import "../src/theme.scss";
// @import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// @import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
// @import "../node_modules/datatables.net-dt/css/jquery.dataTables.css";
// @import "../node_modules/font-awesome/css/font-awesome.min.css";
// @import "../node_modules/ngx-toastr/toastr.css";

$primary-color: #322323;
$secondary-color: #804000;
$primary: #322323;
$light-primary: #3e2723;
$dark-primary: #1b0000;
$secondary: goldenrod;
$dark-secondary: #fbc02d;
$bml-white: #fff;
$lightgray: lightgray;

@mixin bml-custom-btn($color, $background) {
  border-radius: 0;
  //max-width: 100%;
  margin-top: 15px;
  padding: 12px;
  font-weight: bold;
  color: $color;
  border: 1px solid $color;
  background-color: $background;
  font-family: "Poppins", sans-serif;
}

@mixin bml-submit-btn {
  margin-top: 15px;
  padding: 12px;
  border-radius: 0;
  font-family: "Poppins", sans-serif;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  overflow-x: auto;
  padding: 0;
  width: 100%;
}

.mat-typography {
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
}

h1 {
  color: #322323;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  //font-family: "Russo One", sans-serif;
  //font-family: "Archivo Black", sans-serif;
  //font-family: "Alfa Slab One", cursive;
  //font-family: "Fredoka One", cursive;
  //text-shadow: 0px 3px, 3px 0px, 3px 3px;
  font-weight: 700;
  margin-bottom: 25px;
  text-transform: uppercase;
  //line-height: 1.5;
}

h3 {
  font-family: "Poppins", sans-serif;
  //font-family: "Russo One", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

h1.main-title {
  color: #322323;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.5;
  margin-bottom: 50px;
  text-transform: uppercase;
}

hr {
  background: rgb(95, 94, 94);
  border: none;
  height: 3px;
  top: 5px;
}

.policy-link {
  color: blue;
  cursor: pointer;
}

.admin {
  h1 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
  }

  .form-header {
    background-color: $primary-color;
    margin-bottom: 50px;
    padding: 25px;
    text-align: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .bml-default-jumbotron {
    h1 {
      align-items: center;
      background-color: #322323;
      color: #fbc02d;
      display: grid;
      justify-content: center;
      margin-bottom: 0;
      opacity: 0.8;
      padding: 25px;
      text-align: center;
    }
  }
}

.admin-container {
  margin: 25px;
}

.bml-slide {
  background-color: #322323;
  height: 600px;
  width: 100%;
}

.logo-container {
  background-color: #fff;
  text-align: center;

  .bml-logo {
    cursor: pointer;
    text-align: center;
    width: 350px;
  }
}

.account-form-back-link {
  padding-top: 15px;
  text-align: center;

  a {
    font-size: 15px;
  }
}

.form-container {
  padding: 25px 50px;
}

.bml-default-jumbotron {
  background: url("/assets/images/carousel/carousel4.jpg") no-repeat;
  background-size: cover;
  height: 250px;
  margin-bottom: 50px;
}

.jumbotron {
  align-items: center;
  background-color: lightgray;
  border-radius: 0;
  display: grid;
  padding-bottom: 25px;
  padding-top: 25px;

  .title {
    font-size: 40px;
    font-weight: 800;
    line-height: 1.6;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .slogan {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 30px;
  }

  .mat-form-field {
    background-color: #322323;
    padding: 5px 15px 5px 15px;

    mat-label {
      color: #fff;
      font-size: 17px;
    }
  }

  .description {
    font-size: 20px;
    line-height: 1.6;
  }
}

.bml-search-btn {
  border-radius: 2px;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
}

.post-detail {
  img {
    height: auto;
    width: 100%;
  }
}

.bml-stepper-custom-submit-btn {
  @include bml-custom-btn($dark-secondary, $primary);
  outline-style: double;
  border-radius: 5px;

  &:hover {
    background-color: lightgray;
    color: $primary;
    outline-color: lightgray;
  }
}

.bml-stepper-custom-outline-btn {
  @include bml-custom-btn($primary, lightgray);
  border: 1px solid $primary;
  outline-color: lightgray;
  outline-style: double;
  border-radius: 5px;

  &:hover {
    background-color: $primary;
    color: $secondary;
    outline-color: $secondary;
  }
}

.step-text {
  font-size: 30px;
  font-weight: bold;
}

.bml-custom-btn {
  @include bml-submit-btn();
  background-color: $secondary;
  border: 1px solid $primary-color;
  color: $bml-white;
  margin-right: 25px;
}

.bml-custom-submit-btn {
  @include bml-submit-btn();
  background-color: $primary;
  border: 1px solid $bml-white;
  color: $bml-white;

  &:hover {
    background-color: $primary;
    color: #fbc02d;
  }
}

.bml-custom-outline-btn {
  @include bml-submit-btn();
  background-color: $lightgray;
  border: 1px solid $primary;
  border-radius: 5px;
  color: $primary;
  font-size: 20px;
  font-weight: 700;
  opacity: 0.8;
  outline-color: lightgray;
  outline-style: double;

  &:hover {
    background-color: $primary;
    color: $secondary;
    outline-color: $secondary;
  }
}

.request-demo-sticky-right {
  background-color: #fbc02d;
  border-radius: 0;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding: 15px;
  position: fixed;
  right: 0;
  top: 40%;
  writing-mode: vertical-lr;
  z-index: 999;

  a {
    color: $primary-color;
    font-weight: bolder;
    text-decoration: none;
  }
}

.bml-stepper-custom-outline-btn,
.bml-stepper-custom-submit-btn {
  border: 2px solid #3e2723;
  border-radius: 5px;
  //width: 100%;

  &:focus {
    outline: none;
  }

  &.active:hover {
    outline: none;
  }

  & :active {
    outline: none;
  }

  &.active:focus {
    outline: none;
  }

  &:disabled:not(.active) {
    color: #fff;
  }
}

.btn-link {
  background-color: #322323;
  color: #fff;
  padding: 15px;
}

.page-item .page-link {
  color: #322323;
}

.page-item.active .page-link {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $dark-secondary;

  &:focus {
    outline: none;
  }
}

.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}

.bml-card {
  padding: 50px;

  .title {
    font-size: 40px;
    font-weight: 700;
  }
}

.carousel-caption {
  bottom: initial;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}

.slick-slider {
  margin: auto;
}

body .slick-prev,
body .slick-next {
  background: #1b0000 !important;
  color: #fbc02d;
  height: 45px;
  width: 40px;
  z-index: 100;
}

.product-card {
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;

  .image {
    object-fit: cover;
    padding: 5px;
    width: 100%;
  }
}

.product-items-container {
  margin: 15px 0 15px 0;

  .product {
    border: 1px solid lightgray;
  }
}

.no-products {
  border: 3px solid lightgray;
  margin-bottom: 25px;
  padding: 25px;

  .message {
    color: #1b0000;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }
}

// Form input
input,
textarea,
select {
  background-color: #e7e5e5;
}

.form-group {
  select,
  input {
    background-color: #e7e5e5;
    border-radius: 0;
    height: 50px;
  }
}


@media (max-width: 768px) {
  .admin {
    margin: 0;
    padding: 0;

    .bml-default-jumbotron {
      margin-top: -50px;
      padding-top: 0;

      h1 {
        font-size: 20px;
      }
    }
  }
  .title {
    font-size: 15px;
  }

  .slick-slider {
    width: 90%;
  }
}

@media (max-width: 600px) {
  carousel {
    .carousel-indicators,
    .carousel-indicators li {
      cursor: default;
      display: none;
      pointer-events: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  body {
    height: 100%;
    margin: 0;
    overflow-x: auto;
    padding: 0;
    width: 100%;
  }

  .body-content {
    margin: 0px 15px 0px 15px;
    padding: 15px;
  }

  .products,
  .product-detail,
  .shop,
  .shopping-cart,
  .departments,
  .category-detail,
  .brands,
  .subcategories,
  .subcategory,
  .navbar {
    padding: 15px;
  }

  // .product-detail {
  //   padding: 15px;
  // }

  carousel {
    font-size: 10px;

    .carousel-indicators,
    .carousel-indicators li {
      cursor: default;
      display: none;
      pointer-events: none;
    }

    .carousel-caption {
      h1,
      .title {
        font-size: 20px;
        text-shadow: 1px 1px $dark-secondary;
      }
    }
  }

  h1 {
    font-family: "Fredoka One", cursive;
    font-size: 25px;
    margin-bottom: 15px;
  }

  p {
    font-size: 12px;
  }
  .bml-custom-submit-btn,
  .bml-custom-outline-btn {
    font-size: 15px;
    padding: 15px;
  }

  .step-text {
    font-size: 20px;
  }

  .bml-default-jumbotron {
    height: 120px;
  }

  .admin {
    margin: 0;
    padding: 15px;

    .bml-default-jumbotron {
      height: auto;
      padding-bottom: 25px;
    }
  }

  .bml-default-jumbotron {
    height: 120px;

    h1 {
      font-size: 25px;
    }
  }

  .mat-elevation-z8,
  .table {
    overflow-x: scroll;
  }

  .jumbotron {
    padding-bottom: 25px;
    padding-top: 25px;

    .title {
      font-size: 25px;
    }

    .bml-custom-submit-btn,
    .bml-custom-outline-btn {
      font-size: 12px;
      padding: 10px;
    }
  }

  .request-demo-sticky-right {
    display: none;
  }
}

@media (max-width: 1400px) {
  h1 {
    font-size: 35px;
  }
}
